import { Component } from '@angular/core';
import $ from "jquery";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Site | Girafa Digital';

  constructor() {

    $(window).scroll(function () {
      if ($(this).scrollTop() > 50) {
        $('#menufixed').addClass("efeitoMenuFixed");
      } else {
        $('#menufixed').removeClass("efeitoMenuFixed");
      }
    });
    
  }


}
