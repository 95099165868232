<div class="bgZC">

  <div id="menufixed">
    <div class="wrap">
      <div class="menu">
        <img class="logo" src="../assets/logo_girafa_branco.svg" alt="logotipo Girafa Digital">

        <a class="whats" target="_blank" href="https://api.whatsapp.com/send?phone=5547984483109">
          <i class='fab fa-whatsapp'></i>
          Envie uma mensagem
        </a>
      </div>
    </div>
  </div>

  <div class="wrap">
    <div class="menu">
      <img class="logo" src="../assets/logo_girafa_colorido.svg" alt="logotipo Girafa Digital">

      <a class="whats" target="_blank" href="https://api.whatsapp.com/send?phone=5547984483109">
        <i class='fab fa-whatsapp'></i>
        Envie uma mensagem
      </a>
    </div>
  </div>


  <div style="clear: both;"></div>


  <div class="bgZonaComercial">

    <div class="wrap">
      <div class="gridZona">
        <div class="texto">
          <h1>Criação de site profissional</h1>
          <p>Criamos sites que gerem experiências positivas ao usuário com apelo comercial, para que além de gerar leads
            gerem clientes.</p>
        </div>
      </div>
    </div>

  </div>

</div>

<div class="wrap">

  <div class="gridPorqueGirafa">

    <img class="hexGirafa" src="../assets/hex_girafa.svg">

    <div class="texto">
      <h1>Porque confiar na Girafa Digital?</h1>
      <p>Estamos no mercado há mais de 3 anos trabalhando com criação de site, landing page para captação de leads no
        Google Ads, aplicativos e sistema web. Constantemente estamos nos especializando e buscando conhecimento para
        poder trazer recursos mais efetivos aos nossos clientes.</p>
    </div>

  </div>
</div>

<div class="bgformulario">
  <div class="wrap">
    <div class="formulario">
      <div>
        <div class="boxFormulario">
          <div class="texto">
            <h1>Quero fazer um site</h1>
            <p>e destacar a minha empresa</p>
          </div>


          <form>
            <p>Nome</p>
            <input type="text" placeholder="Seu nome aqui">

            <p>E-mail</p>
            <input type="text" placeholder="example@email.com">

            <button class="buttonEnviar" type="submit">Enviar contato</button>
          </form>

        </div>
      </div>

      <div class="img">
        <img src="../assets/monitor-girafa-digital.png">
      </div>

    </div>
  </div>
</div>

<div class="wrap">

  <div class="nossoSite">
    <h1>Nossos site são:</h1>
    <div class="texto">

      <div class="flexItem">
        <i class='far fa-check-circle'></i>
        <p>Responsivo (possuem versão para celular e tablet)</p>
      </div>

      <div class="flexItem">
        <i class='far fa-check-circle'></i>
        <p>Apresentam a empresa</p>
      </div>

      <div class="flexItem">
        <i class='far fa-check-circle'></i>
        <p>Oferecerem uma experiência ao usuário</p>
      </div>

      <div class="flexItem">
        <i class='far fa-check-circle'></i>
        <p>Podem ser dinâmico (com o Wordpress)</p>
      </div>


    </div>
  </div>
</div>

<div class="bgPortfolio">
  <div class="wrap">
    <div class="portfolio">

      <div class="titulo">
        <h1>Alguns dos sites desenvolvidos</h1>
        <p>por nossa equipe</p>
      </div>

      <div class="cardPorfolio">
        <div class="texto">
          <h2>ICETEC SOLUTIONS</h2>
          <p>A Icetec Solutions, empresa focada em soluções digitais pelo desenvolvimento de software e aplicativos
            híbridos e nativos, veio até a Girafa Digital com o desafio de ganhar mais visibilidade na internet e
            consequentemente atrair mais clientes.
          </p>
          <p>
            Dessa forma, utilizando as tecnologias de HTML, CSS e Wordpress conseguimos criar uma vitrine para o
            negócio,
            com uma apresentação dinâmica e atraente.</p>
        </div>

        <img src="../assets/icetec.png">

      </div>

      <div class="cardPorfolio">
        <img src="../assets/kappa.png">

        <div class="texto">
          <h2>Kappa Soluções</h2>
          <p>Kappa Soluções, uma empresa de consultoria e treinamento em engenharia industrial, logística e supply
            chain.
            A Girafa Digital recebeu como desafio desenvolver a visibilidade da empresa através da criação de site e do
            blog (ou como o cliente gosta de chamar: midia center).
          </p>
          <p>
            O site foi desenvolvido diretamente para a plataforma do Wordpress possibilitando que algumas partes do site
            sejam editável de forma dinâmica para que o cliente tenha autonomia no ajuste do conteúdo do site de acordo
            com as suas necessidades.
          </p>
        </div>
      </div>

      <div class="cardPorfolio">

        <div class="texto">
          <h2>Woowtech</h2>
          <p>Objetivo principal do projeto é a criação de uma plataforma que possua duas funcionalidade: 1) utilização
            de
            um robô para iniciar a conversação com os clientes; 2) desenvolvimento de um chat real time para
            complementar
            a conversação do robô.
          </p>
          <p>
            A Girafa Digital aceitou o desafio para criar um layout clean e com uma boa usabilidade, assim como o
            desenvolvimento de todo o front-end. Após a finalização do sistema web, a Woowtech também contratou a
            criação
            e desenvolvimento do site em Wordpress, a única observação é que algumas partes teria que ser editável pelo
            cliente como FAQ (perguntas frequentes).
          </p>
        </div>

        <img src="../assets/woowtech.png">

      </div>

    </div>
  </div>
</div>

<div class="wrap">
  <div class="formularioSegundo">

    <div class="boxFormulario">

      <div class="texto">
        <h1>Quero fazer um site</h1>
        <p>e destacar a minha empresa</p>

        <img class="circulo" src="../assets/grupo_circulo.svg">
      </div>

      <form>
        <p>Nome</p>
        <input type="text" placeholder="Seu nome aqui">

        <p>E-mail</p>
        <input type="text" placeholder="example@email.com">

        <button class="buttonEnviar" type="submit">Enviar contato</button>
      </form>

    </div>
  </div>

</div>

<div class="rodape">
  <div class="wrap">
    <div class="displayRodape">

      <img class="logoRodape" src="../assets/logo_girafa_branco.svg">

      <div class="contato">
        <div class="itemContato">
          <i class='fab fa-whatsapp'></i>
          <p>+55 (47) 98448-3109</p>
        </div>

        <div class="itemContato">
          <i class="material-icons">mail_outline</i>
          <p>contato@girafa.digital</p>
        </div>

      </div>

    </div>
  </div>
</div>

<div class="wrap">
  <div class="pCopyright">
    <p>© 2020. Todos os direitos reservados a <a href="https://girafa.digital/" target="_blank">Agência Girafa
        Digital</a>.</p>
  </div>
</div>